import React from 'react'
import { connect } from 'react-redux'

import { RangeFilter, RefinementListFilter } from 'searchkit'
import RefinementOption from './refinementOption'
import screenTypesMap from '../../../layout/_parts/screenTypesMap'

import * as actionCreators from '../../../store/actions'
import { SelectedForComparisonFilter } from './selectedForComparisonFilter'

const classNames = require('classnames')

export class SearchFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filterPanelVisible: false,
    }
  }

  openCloseFilterPanel = e => {
    e.stopPropagation()
    const { filterPanelVisible } = this.state
    this.setState({ filterPanelVisible: !filterPanelVisible })
  }

  render() {
    const { texts, device, comparisonBoard, environment } = this.props
    const { filterPanelVisible } = this.state
    const { addProductPopup } = comparisonBoard
    const { config } = environment

    return (
      <div className={classNames({ search_filter: true })}>
        <div
          className={classNames({
            search_filter_btn: true,
            theme_background_color: true,
          })}
          role="button"
          tabIndex={0}
          onClick={e => {
            this.openCloseFilterPanel(e)
          }}
          onKeyPress={e => {
            this.openCloseFilterPanel(e)
          }}
        >
          {texts.filters}
        </div>
        <div
          className={classNames({
            search_filter_container: true,
            search_filter_container_visible: filterPanelVisible,
            search_filter_container_hidden: !filterPanelVisible,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
        >
          {addProductPopup.visible === true && <SelectedForComparisonFilter {...this.props} />}
          {JSON.parse(process.env.filterByGlobalScoreEnabled) === true && (
            <RangeFilter
              field="global_score"
              id="global_score"
              min={0}
              max={JSON.parse(config.max_product_score)}
              showHistogram={false}
              title={texts.global_score}
            />
          )}
          <RefinementListFilter
            id="status"
            title={texts.status}
            field="status"
            operator="OR"
            itemComponent={RefinementOption}
          />
          <RefinementListFilter
            id="brand"
            title={texts.brand}
            field="props.brand.value"
            operator="OR"
            itemComponent={RefinementOption}
          />
          <RefinementListFilter
            id="model"
            title={texts.model}
            field="props.model.value"
            operator="OR"
            itemComponent={RefinementOption}
          />
          <RefinementListFilter
            id="segment"
            title={texts.segment}
            field="props.main-segment.value"
            operator="OR"
            itemComponent={RefinementOption}
          />
          <RefinementListFilter
            id="template"
            title={texts.template}
            field="template.name"
            operator="OR"
            itemComponent={RefinementOption}
          />
          <RefinementListFilter
            id="author"
            title={texts.author}
            field="author.name"
            operator="OR"
            itemComponent={RefinementOption}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    config: state.environment.config,
    comparisonBoard: state.comparisonBoard,
    searchkit: state.search.searchkit,
  }
}

export default connect(mapStateToProps, actionCreators)(SearchFilter)
